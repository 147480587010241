import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="homoeopathie" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Homöopathie`}</h1>
      <h3 {...{
        "id": "homoopathie"
      }}>{`Homöopathie`}</h3>
      <p>{`Bereits gegen Ende des 18. Jahrhunderts machte der deutsche Arzt Samuel Hahnemann die Beobachtung, dass Substanzen in stark verdünnter (homöopathisch potenzierter) Form Krankheiten heilen können, die durch ebendiese Substanzen in hoher Konzentration hervorgerufen werden.`}</p>
      <p>{`Diese homöopathischen Arzneimittel sind in der Lage, wenn sie symptomenspezifisch individuell ausgesucht und gefunden sind, Selbstheilungskräfte des Organismus anzuregen.`}</p>
      <p>{`Die Kunst des Homöopathen besteht nun darin, aus der Fülle der vorhandenen Mittel das für den einzelnen Patienten in seiner momentanen körperlichen und psychischen Verfassung passende Mittel in der geeigneten Potenz zu finden.`}</p>
      <p>{`Heute weiß man aus der Photonenforschung, dass es nicht die chemische Wirksubstanz des einzelnen Mittels ist, die die oft verblüffende Heilwirkung hervorruft, sondern elektromagnetische Schwingungsmuster, die im Körper entsprechende Resonanzen / Heilimpulse (s.a. `}<a parentName="p" {...{
          "href": "/therapien/bioresonanz-therapie"
        }}>{`Bioresonanz-Therapie`}</a>{` ) erzeugen.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      